import React, { createRef, FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Arrow from 'common/icons/arrow-right.svg';
import LinkItem from 'common/LinkItem';
import { isBrowser } from 'utils/browser';
import { TABLET_DEVICE } from 'utils/constants';

import { ModulesContent } from '../../@types/content/modules';
import Blocks from './Blocks';
import { SwitcherBlockProps } from './models';

import './SwitcherBlock.scss';

const SwitcherBlock: FC<SwitcherBlockProps> = ({ module }) => {
  const [activeBlock, setActiveBlock] = useState<ModulesContent.SwitcherBlockItemType>(
    module.blocks[0]
  );
  const containerRef = createRef<HTMLDivElement>();
  const [screen, setScreen] = useState<number | boolean>();

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }
  }, []);

  const switchBlockHandle = (e) => {
    const title = e.currentTarget.dataset.value;
    const active = module.blocks.filter((block) => block.title === title);
    setActiveBlock(active[0]);

    if (!screen) return;

    if (screen < TABLET_DEVICE) {
      containerRef.current?.scrollIntoView();
    }
  };

  return (
    <section className="switcher-block">
      <div className="switcher-block__holder">
        <Container>
          <Row>
            <Col xl="5" md="7" sm="12" className="col--no-gutters switcher-block__left-col">
              <span className="switcher-block__section-title">{module.sectionTitle}</span>
              <h2 className="switcher-block__title h4">{module.title}</h2>
              <p className="switcher-block__text">{module.summaryText}</p>
              <LinkItem
                link={module.ctaLink}
                linkClass="switcher-block__cta btn btn--pink"
                hasIcon
                isLink
                showTitle
              />
              <ul className="switcher-block__btns-list" role="tablist">
                {module.blocks?.map((block) => (
                  <li
                    key={block.title}
                    className={classNames('switcher-block__btns-item', {
                      'switcher-block__btns-item--active': activeBlock.title === block.title,
                    })}
                    data-testid={`list-${block.title}`}
                    role="presentation"
                  >
                    <button
                      type="button"
                      className="switcher-block__btn h6 switcher-block__heading"
                      role="tab"
                      id={`${objectHash({ btnId: block.title })}-block`}
                      data-value={block.title}
                      onClick={switchBlockHandle}
                      data-testid={block.title}
                      aria-selected={activeBlock.title === block.title}
                      tabIndex={activeBlock.title === block.title ? 0 : -1}
                      aria-controls="switcher-block"
                    >
                      {block.title}
                      <span className="switcher-block__btn-icon-container" aria-hidden="true">
                        <Arrow className="switcher-block__btn-icon" />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xl="6" md="5" sm="12" className="col--no-gutters switcher-block__right-col">
              <div ref={containerRef}>
                <Blocks activeBlock={activeBlock} />
              </div>
            </Col>
          </Row>
        </Container> 
      </div>
    </section>
  );
};

export default SwitcherBlock;
